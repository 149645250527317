import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

let localCart = localStorage.getItem("local_els_cart");
let cartData = localCart && JSON.parse(localCart);
//
if (cartData == null) {
  localStorage.setItem("local_els_cart", JSON.stringify([]));
  // state.items=[];
}
const initialState = {
  items: cartData != null ? [...cartData] : [],
  totalPrice: 0,
  totalQuantity: 0,
};

const calculateTotalPriceAndQuantity = (items) => {
  let totalPrice = 0;
  let totalQuantity = 0;

  items.forEach((item) => {
    totalPrice += item.price * item.quantity;

    // Add the price and quantity of each option
    if (item?.options) {
      item?.options.forEach((option) => {
        totalPrice += parseFloat(option.price) * option.quantity;
      });
    }

    totalQuantity += item.quantity;
  });
  totalPrice += totalPrice > 10 ? 0 : 1.5;

  return { totalPrice, totalQuantity };
};

const offlineCartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItem(state, action) {
      const newItem = action.payload;

      const existingItemIndex = state.items.findIndex(
        (item) => item.id === newItem.id
      );

      if (existingItemIndex !== -1) {
        state.items[existingItemIndex].quantity += newItem.quantity;
      } else {
        // state.items.push(newItem);
        toast.success(
          localStorage.getItem("greenTreesLanguage") == "ar" ||
            !localStorage.getItem("greenTreesLanguage")
            ? "تمت إضافة العنصر "
            : "Item added successfully"
        );
      }

      const { totalPrice, totalQuantity } = calculateTotalPriceAndQuantity(
        state.items
      );
      state.totalPrice = totalPrice;

      state.totalQuantity = totalQuantity;
      let localCart = localStorage.getItem("local_els_cart");
      let cartData = localCart && JSON.parse(localCart);
      let pushedCart = [];
      if (cartData.length == 0) {
        pushedCart.push({ ...newItem, uiid: cartData.length });
      } else {
        pushedCart = [...cartData];
        for (let i = 0; i < cartData.length; i++) {
          if (cartData[i].id == newItem.id) {
            pushedCart[i]["quantity"] = pushedCart[i].quantity * 1 + 1;
            break;
          }
          if (i == cartData.length - 1 && cartData[i].id != newItem.id) {
            pushedCart.push({ ...newItem, uiid: cartData.length });
          }
        }
      }
      localStorage.setItem("local_els_cart", JSON.stringify(pushedCart));
      state.items = [...pushedCart];
    },
    updateQuantity(state, action) {
      const { id, quantity } = action.payload;
      const itemToUpdate = state.items.find((item) => item.id === id);

      if (itemToUpdate) {
        itemToUpdate.quantity = quantity;
      }

      const { totalPrice, totalQuantity } = calculateTotalPriceAndQuantity(
        state.items
      );
      state.totalPrice = totalPrice;

      state.totalQuantity = totalQuantity;
    },
    removeItem(state, action) {
      const itemIdToRemove = action.payload?.itemIdToRemove;

      state.items = state.items.filter((item) => item.uiid !== itemIdToRemove);

      toast.success(
        localStorage.getItem("greenTreesLanguage") == "ar"
          ? "تم حذف العنصر "
          : "Item removed successfully"
      );

      const { totalPrice, totalQuantity } = calculateTotalPriceAndQuantity(
        state.items
      );
      let localCart = localStorage.getItem("local_els_cart");
      let cartData = localCart && JSON.parse(localCart);

      let pushedCart = cartData.filter((item) => item.uiid != itemIdToRemove);
      //
      // return
      state.items = [...pushedCart];
      localStorage.setItem("local_els_cart", JSON.stringify(pushedCart));
      state.totalPrice = totalPrice;

      state.totalQuantity = totalQuantity;
    },
    removeWeightItem(state, action) {
      const itemIdToRemove = action.payload?.itemIdToRemove;
      const weight = action.payload?.weight;
      state.items = state.items.filter(
        (item) => item.id !== itemIdToRemove && item.weight != weight
      );
      toast.success(
        localStorage.getItem("greenTreesLanguage") == "ar"
          ? "تم حذف العنصر "
          : "Item removed successfully"
      );

      const { totalPrice, totalQuantity } = calculateTotalPriceAndQuantity(
        state.items
      );
      let localCart = localStorage.getItem("local_els_cart");
      let cartData = localCart && JSON.parse(localCart);
      let pushedCart = [];
      for (let i = 0; i < cartData.length; i++) {
        if (cartData[i].weight != weight && cartData[i].id != itemIdToRemove) {
          pushedCart.push(cartData[i]);
        }
        if (cartData[i].weight != weight && cartData[i].id == itemIdToRemove) {
          pushedCart.push(cartData[i]);
        }
      }
      //  pushedCart=cartData.filter((item)=>{
      //
      //   if(item.id!=itemIdToRemove&&item.weight!=weight){
      //     return {...item}
      //   }
      //   else return null
      // })
      //
      // return
      state.items = [...pushedCart];
      localStorage.setItem("local_els_cart", JSON.stringify(pushedCart));
      state.totalPrice = totalPrice;

      state.totalQuantity = totalQuantity;
    },
    clearCart(state) {
      state.items = [];
      state.totalPrice = 0;
      state.totalQuantity = 0;
    },
    incrementQuantity(state, action) {
      const itemIdToIncrement = action.payload?.itemIdToIncrement;
      const item = action.payload?.item;
      const language = action.payload?.language;
      const itemToIncrement = state.items.find(
        (item) => item.id === itemIdToIncrement
      );

      if (itemToIncrement) {
        state.items = state.items.map((item) => {
          if (item.id === itemIdToIncrement) {
         
              item.quantity += 1;
            

            if (item?.options) {
              item?.options.forEach((option) => {
                option.quantity += 1;
                option.totalPrice = parseFloat(option.price) * option.quantity;
              });
            }
          }
          return item;
        });
      }

      const { totalPrice, totalQuantity } = calculateTotalPriceAndQuantity(
        state.items
      );

      state.totalPrice = totalPrice;

      state.totalQuantity = totalQuantity;
      let localCart = localStorage.getItem("local_els_cart");
      let cartData = localCart && JSON.parse(localCart);
      let pushedCard = [];
      for (let i = 0; i < cartData.length; i++) {
        let obj = {
          ...cartData[i],
        };
        if (cartData[i].id == itemIdToIncrement) {
          console.log("item", item)
          if ((obj["quantity"] + 1 > item?.avilabel_stock)) {
            toast.error(
             localStorage.getItem("greenTreesLanguage") == "ar"
                ? "لا يتوفر المزيد م المنتج"
                : "No more product"
            );
          } else {
            obj["quantity"] = obj["quantity"] * 1 + 1;
          }
        }

       
        pushedCard.push(obj);
      }
      localStorage.setItem("local_els_cart", JSON.stringify(pushedCard));
      state.items = [...pushedCard];

      // return state;
    },
    incrementWeightQuantity(state, action) {
      const itemIdToIncrement = action.payload?.itemIdToIncrement;
      const weight = action.payload?.weight;
      const itemToIncrement = state.items.find(
        (item) => item.id === itemIdToIncrement
      );

      if (itemToIncrement) {
        state.items = state.items.map((item) => {
          if (item.id === itemIdToIncrement && item.weight == weight) {
            // Increment quantity of the main product
            item.quantity += 1;

            // Increment quantity and update total price of options
            if (item?.options) {
              item?.options.forEach((option) => {
                option.quantity += 1;
                option.totalPrice = parseFloat(option.price) * option.quantity;
              });
            }
          }
          return item;
        });
      }

      const { totalPrice, totalQuantity } = calculateTotalPriceAndQuantity(
        state.items
      );
      state.totalPrice = totalPrice;
      state.totalQuantity = totalQuantity;

      let localCart = localStorage.getItem("local_els_cart");
      let cartData = localCart && JSON.parse(localCart);
      let pushedCard = [];
      for (let i = 0; i < cartData.length; i++) {
        let obj = {
          ...cartData[i],
        };
        if (
          cartData[i].id == itemIdToIncrement &&
          cartData[i].weight == weight
        ) {
          obj["quantity"] = obj["quantity"] * 1 + 1;
        }
        pushedCard.push(obj);
      }
      localStorage.setItem("local_els_cart", JSON.stringify(pushedCard));
      state.items = [...pushedCard];

      // return state;
    },
    decrement2(state, action) {
      let localCart = localStorage.getItem("local_els_cart");
      let cartData = localCart && JSON.parse(localCart);

      let pushedCard = [];
      for (let i = 0; i < cartData.length; i++) {
        let obj = {
          ...cartData[i],
        };

        if (cartData[i].id == action.payload.id) {
          if (obj["quantity"] != 1) {
            obj["quantity"] = obj["quantity"] * 1 - 1;
            pushedCard.push(obj);
          }
          if (cartData[i]["quantity"] == 1) {
          }
        } else {
          pushedCard.push(obj);
        }
      }
      localStorage.setItem("local_els_cart", JSON.stringify(pushedCard));

      state.items = [...pushedCard];
    },
    decrementQuantity(state, action) {
      const itemIdToDecrement = action.payload?.itemIdToIncrement;
      let localCart = localStorage.getItem("local_els_cart");
      let cartData = localCart && JSON.parse(localCart);

      let pushedCard = [];
      for (let i = 0; i < cartData.length; i++) {
        let obj = {
          ...cartData[i],
        };

        if (cartData[i].id == action.payload.itemIdToDecrement) {
          if (obj["quantity"] != 1) {
            obj["quantity"] = obj["quantity"] * 1 - 1;
            pushedCard.push(obj);
          }
          if (cartData[i]["quantity"] == 1) {
          }
        } else {
          pushedCard.push(obj);
        }
      }
      localStorage.setItem("local_els_cart", JSON.stringify(pushedCard));

      state.items = [...pushedCard];

      const itemToDecrement = state.items.find(
        (item) => item.id === itemIdToDecrement
      );

      if (itemToDecrement && itemToDecrement.quantity > 1) {
        // state.items = state.items.map((item) => {
        //   if (item.id === itemIdToDecrement) {
        //     // Decrement quantity of the main product
        //     item.quantity -= 1;
        //     // Decrement quantity and update total price of options
        //     if (item?.options) {
        //       item?.options.forEach((option) => {
        //         option.quantity -= 1;
        //         option.totalPrice = parseFloat(option.price) * option.quantity;
        //       });
        //     }
        //   }
        //   return item;
        // });
      }

      const { totalPrice, totalQuantity } = calculateTotalPriceAndQuantity(
        state.items
      );
      state.totalPrice = totalPrice;

      state.totalQuantity = totalQuantity;

      // return state;
    },
    addWithWeight(state, action) {
      let pushedCart = action.payload?.pushedCart;

      let lastPushed = pushedCart.map((item, index) => {
        return { ...item, uiid: index };
      });
      state.items = [...lastPushed];
      localStorage.setItem("local_els_cart", JSON.stringify(lastPushed));
    },
    emptyCart(state, action) {
      let pushedCart = [];
      localStorage.setItem("local_els_cart", JSON.stringify(pushedCart));
      state.items = [];
    },
    decrementWeightQuantity(state, action) {
      const itemIdToDecrement = action.payload?.itemIdToIncrement;
      const weight = action.payload?.weight;
      let localCart = localStorage.getItem("local_els_cart");
      let cartData = localCart && JSON.parse(localCart);

      let pushedCard = [];
      for (let i = 0; i < cartData.length; i++) {
        let obj = {
          ...cartData[i],
        };

        if (
          cartData[i].id == action.payload.itemIdToDecrement &&
          cartData[i].weight == weight
        ) {
          if (obj["quantity"] != 1) {
            obj["quantity"] = obj["quantity"] * 1 - 1;
            pushedCard.push(obj);
          }
          if (cartData[i]["quantity"] == 1) {
          }
        } else {
          pushedCard.push(obj);
        }
      }
      localStorage.setItem("local_els_cart", JSON.stringify(pushedCard));

      state.items = [...pushedCard];

      const itemToDecrement = state.items.find(
        (item) => item.id === itemIdToDecrement
      );

      if (itemToDecrement && itemToDecrement.quantity > 1) {
        // state.items = state.items.map((item) => {
        //   if (item.id === itemIdToDecrement) {
        //     // Decrement quantity of the main product
        //     item.quantity -= 1;
        //     // Decrement quantity and update total price of options
        //     if (item?.options) {
        //       item?.options.forEach((option) => {
        //         option.quantity -= 1;
        //         option.totalPrice = parseFloat(option.price) * option.quantity;
        //       });
        //     }
        //   }
        //   return item;
        // });
      }

      const { totalPrice, totalQuantity } = calculateTotalPriceAndQuantity(
        state.items
      );
      state.totalPrice = totalPrice;

      state.totalQuantity = totalQuantity;

      // return state;
    },
  },
});

export const {
  addItem,
  updateQuantity,
  removeItem,
  clearCart,
  decrement2,
  incrementQuantity,
  decrementWeightQuantity,
  incrementWeightQuantity,
  addWithWeight,
  decrementQuantity,
  emptyCart,
  removeWeightItem,
} = offlineCartSlice.actions;

export default offlineCartSlice.reducer;
