import React, { useState, useEffect } from "react";
import BottomHeader from "../../components/header/bottomHeader";
import MiddleHeader from "../../components/header/middleHeader";
import TopHeader from "../../components/header/topHeader";
import { homeCategories } from "../../data/homeCategories";
import "./style.css";

const Header = ({ setShowSearchModal }) => {
  const [isSticky, setIsSticky] = useState(false);
const [menuOpen, setMenuOpen] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 160) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [window]);

  return (
    <header className={isSticky ? "sticky-header" : ""}>
      {/* {!isSticky ? ( */}
        <div className="headerBackground">
          {/* <TopHeader /> */}
          <MiddleHeader
           menuOpen={menuOpen} 
           setMenuOpen={setMenuOpen}
            isSticky={isSticky}
            setShowSearchModal={setShowSearchModal}
          />
        </div>
      {/* ) : null} */}
      <BottomHeader
      menuOpen={menuOpen} 
      setMenuOpen={setMenuOpen}
        setShowSearchModal={setShowSearchModal}
        homeCategories={homeCategories}
      />
    </header>
  );
};

export default Header;
