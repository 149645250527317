import React, { useEffect } from 'react';
import CategoriesProducts from '../../layouts/categoriesProducts';
import UseGeneral from '../../hooks/useGeneral';

const Product = () => {
  const {language} = UseGeneral()
  useEffect(() => {
    document.title =
      language == "ar" ? "الصيداوي | المنتجات   " : "Al-Seedawi | Products";
  }, []);
  return (
    <div>
      <CategoriesProducts />
    </div>
  );
};

export default Product;
