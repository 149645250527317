import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Axios } from "../../../Axios";
import { BASE_URL } from "../../../Axios/base_url";
import { Favourite, Profile, cart, searchIcon } from "../../../assets/svgIcons";
import UseCartFavourite from "../../../hooks/useCartFavourite";
import UseGeneral from "../../../hooks/useGeneral";
import CartDrop from "./cardDrop";
import "./style.css";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";
const HeaderIcons = ({ setShowSearchModal }) => {
  const { language } = UseGeneral();
  const cartLength = useSelector((state) => state.cart.data);
  const [top, setTop] = useState(0);
  const [cartNumber, setCartNumber] = useState(0);
  useEffect(() => {
    if (
      document.querySelectorAll("header") &&
      document.querySelectorAll("header")?.length
    ) {
      setTop(document.querySelector("header")?.clientHeight);
    }
  }, [document.querySelector("header")?.clientHeight]);
  const {
    cart: cart_2,
    favourite,
    totalPrice,
    CartNumber,
    // getUserData,
    allCart,
  } = UseCartFavourite({ setCartNumber });
  const navigate = useNavigate();
  const localCartLength = useSelector((state) => state.offlineCart);
  const [searchResult, setSearchResult] = useState([]);
  const searchInput = useRef();
  const [loading, setLoading] = useState(false);
  let localCart = localStorage.getItem('local_els_cart');
  let cartArr = localCart && JSON.parse(localCart);
  // let totalPrice=
  //
  let cartDataTotalPrice = 0;
  if (cartArr && cartArr.length) {
    for (let k = 0; k < cartArr.length; k++) {
      cartDataTotalPrice += cartArr[k].price * 1 * cartArr[k].quantity;
      if (
        cartArr[k].product_optionsarr &&
        cartArr[k].product_optionsarr.length > 0
      ) {
        let options = [...cartArr[k].product_optionsarr];
        for (let y = 0; y < options.length; y++) {
          //
          cartDataTotalPrice += options[y].price * 1 + options[y].quantity * 1;
        }
      }
    }
  }
  const search = (value) => {
    if (!value || !value?.length) {
      setSearchResult([]);
      return;
    }
    setLoading(true);
    Axios({
      url: BASE_URL + `homepage/search_products`,
      method: "post",
      data: {
        search_txt: value,
      },
    })
      .then((response) => {
        if (response?.result?.products && response?.result?.products?.length)
          setSearchResult(response?.result?.products);
        else {
          setSearchResult([]);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const [showCart, setShowCart] = useState(false);
  useEffect(() => {
    if (showCart) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "initial";
    }
  }, [showCart]);
  return (
    <div className="HeaderIcons">
      {/* <span onClick={() => navigate("/Favourite")}>
        {searchIcon}
      </span> */}
      <div className="d-flex gap-332">
        <span
          onClick={() => {
            setShowSearchModal(true);
          }}
        >
          {searchIcon}
        </span>
        <span
          onClick={() => {
            // setShowCart(!showCart);
            navigate("/Cart");

          }}
        >
          {totalPrice > 0 ? <sub>{totalPrice}$</sub> : null}
          {cart}
          {
            <sup>
              {Array.isArray(localCartLength.items)
                ? localCartLength.items.length
                : 0}
            </sup>
          }
        </span>
        <div
          className={!showCart ? "dropMenuFixed" : "dropMenuFixed show"}
          style={{
            top: top,
            height: ` calc(
            100% - ${document.querySelector("header")?.clientHeight}px
          )`,
          }}
        >
          <CartDrop setShowCart={setShowCart} />
        </div>
        {/* <span onClick={() => navigate("/Favourite")}>
          {Favourite}
          {favourite?.length > 0 ? <sup>{favourite?.length}</sup> : null}
        </span> */}
      </div>
    </div>
  );
};

export default HeaderIcons;
