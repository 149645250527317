import React, { useEffect, useState } from "react";
import { returnItem } from "../../assets/svgIcons";
import UseCartFavourite from "../../hooks/useCartFavourite";
import UseGeneral from "../../hooks/useGeneral";
import { FaCartArrowDown, FaHeart } from "react-icons/fa";
import ProductInCartMangeButton from "../productInCartMangeButton/productInCartMangeButton";
import Options from "../options/options";
import PopUp from "../popup";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import toast from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { fetchCartData } from "../../store/cartReducer";
import { useDispatch, useSelector } from "react-redux";
import { CiHeart } from "react-icons/ci";
import { MdOutlineChangeCircle } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import {
  addItem,
  addWithWeight,
} from "../../store/offlineCartReducer/OfflineCartReducer";
import Skeleton from "react-loading-skeleton";

const ProductDetailsTexts = ({
  eqData,
  addToCartWithOptions,
  getCartDetails,
  item,
  choosedOption,
  setChoosedOptions,
  getItmData,
}) => {
  const offlineCart = useSelector((state) => state.offlineCart);
  const localData = localStorage.getItem("green_trees");
  const userId = localData && JSON.parse(localData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pushPrice, setPushPrice] = useState('');
  const { update, isItemInFavourite, addToFavourite, removeFromFavourite } =
    UseCartFavourite();

  const { language } = UseGeneral();
  const [showChangeWeight, setShowChangeWeight] = useState(false);
  const [openQuanModel, setOpenQuanModel] = useState("");
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [showRetPop, setShowRetPop] = useState(false);
  const [showEditWeight, setShowEditWeight] = useState(false);
  const [weights, setWeights] = useState([]);
  const [weightsLoading, setWeightsLoading] = useState(false);
  const [selectedWeights, setSelectedWeights] = useState([]);
  // const [openQ]
  const [editLoading, setEditLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  const [showChangeWeight2, setShowChangeWeight2] = useState(false);
  const [openQuantity, setOpenQuantity] = useState(false);
  // const [return]
  const [showOpenWeight, setShowOpenWeight] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [delLoading, setDelLoading] = useState(false);
  const [cartId, setCartId] = useState('');
  const [returnpolicies, setReturnpolicies] = useState([]);
  const [totalPrice, setTotalPrice] = useState(item?.price);
  // const [itemWithWeight,setItemWithWeight]=useState({});
  const [weight, setWeight] = useState({});
  const [open, setOpen] = useState(false);
  const [weights2, setWeights2] = useState([]);
  const [productWeights, setProductWeights] = useState([]);
  const [openWeight, setOpenWeight] = useState(false);
  const [weightLoading, setWeightLoading] = useState(false);
  // toast.success('reeef')
  const handleChangeQuan = (quan, id, type) => {
    
    let newOptions = [...item.options];
    let originalOpts = [...item.options];
    for (let i = 0; i < newOptions.length; i++) {
      if (newOptions[i].id == id) {
        
        newOptions[i]["quantity"] = quan;
      }
      // setOptions(newOptions);
    }
    for (let i = 0; i < originalOpts.length; i++) {
      if (originalOpts[i].id == id) {
        
        if (type == "plus") {
          setTotalPrice(totalPrice * 1 + originalOpts[i].price * 1);
        } else {
          setTotalPrice(totalPrice * 1 - originalOpts[i].price * 1);
        }
        // if(quan>originalOpts[i].quantity){
        //   setTotalPrice(totalPrice*1-originalOpts[i].price*1);
        // }
        // else {
        //   setTotalPrice(totalPrice*1+originalOpts[i].price*1);
        // }
      }
    }
  };
  const addToCart = () => {
    if (item.productweights && item.productweights.length) {
      let weightsList = [...productWeights];
      let n = 0;
      for (let i = 0; i < weightsList.length; i++) {
        if (weightsList[i].selected) {
          n++;
        }
      }
      if (n == 0) {
        toast.error(
          language == 'ar'
            ? 'من فضلك إختر وزن من الموجود'
            : "Please Selected Weight"
        );
        return;
      }
      let localCart = localStorage.getItem('local_els_cart');
      let cartData = localCart && JSON.parse(localCart);
      let pushedCart = [...cartData];
      for (let i = 0; i < weightsList.length; i++) {
        if (weightsList[i].selected) {
          let newobj = {
            ...item,
            id: item.id,
            quantity: 1,
            title_ar: item.title_ar,
            title_en: item.title_en,
            image: item.images && item.images[0] && item.images[0].url,
            weight: weightsList[i].weight,
            discount: item.discount,
            price: weightsList[i].price,
            price_for: "weight",
            total_price: weightsList[i].price * 1,
          };
          
          pushedCart.push(newobj);
          
        }
      }
      dispatch(addWithWeight({ pushedCart: pushedCart }));
    }
    let product_options = "";
    let optionList = [...choosedOption];
    
    for (let i = 0; i < optionList.length; i++) {
      if (optionList[i].quantity != 0) {
        if (product_options.length == 0) {
          product_options +=
            optionList[i].id + "*green*" + optionList[i]["quantity"];
        } else {
          product_options +=
            "**green**" +
            optionList[i].id +
            "*green*" +
            optionList[i]["quantity"];
        }
      }
    }
    let weightsArr = [...weights];
    let weightstxt = "";
    for (let i = 0; i < weightsArr.length; i++) {
      if (weightsArr[i].product_count > 0) {
        if (weightstxt == "") {
          weightstxt +=
            weightsArr[i].weight +
            "*green*" +
            weightsArr[i].price +
            "*green*" +
            weightsArr[i].product_count;
        } else {
          weightstxt +=
            "**green**" +
            weightsArr[i].weight +
            "*green*" +
            weightsArr[i].price +
            "*green*" +
            weightsArr[i].product_count;
        }
      }
    }
    let optionList2 = [...choosedOption];
    let pushedOptions = [];
    setAddLoading(false);
    let product_options2 = "";
    for (let i = 0; i < optionList2.length; i++) {
      if (optionList2[i].quantity != 0) {
        pushedOptions.push(optionList2[i]);
        if (product_options.length == 0) {
          product_options2 +=
            optionList2[i].id + "*green*" + optionList2[i]["quantity"];
        } else {
          product_options2 +=
            "**green**" +
            optionList2[i].id +
            "*green*" +
            optionList2[i]["quantity"];
        }
      }
    }
    
    if (item.price_for == 'weight') {
      let localCart = localStorage.getItem('local_els_cart');
      let CartData = localCart && JSON.parse(localCart);
      let pushedCart = [...CartData];

      for (let i = 0; i < weightsArr.length; i++) {
        if (weightsArr[i].product_count * 1 > 0) {
          let obj = {
            id: item.id,
            quantity: weightsArr[i].product_count,
            title_ar: item.title_ar,
            title_en: item.title_en,
            image: item.images[0] && item.images[0].url,
            weight: weightsArr[i].weight,
            discount: item.discount,
            price: weightsArr[i].price,
            price_for: item.price_for,
            product_options: product_options,
            has_option: product_options != '' ? 1 : 0,
            total_price:
              weightsArr[i].price * 1 * weightsArr[i].product_count * 1,
          };
          
          // return
          // dispatch(addItem(obj))
          pushedCart.push(obj);
        }
      }
      dispatch(addWithWeight({ pushedCart: pushedCart }));
    } else {
      let pushedItem = {
        id: item.id,
        quantity: 1,
        title_ar: item.title_ar,
        title_en: item.title_en,
        image: item.images && item.images[0] && item.images[0].url,
        discount: item.discount,
        price: item.price,
        price_for: item.price_for,
        product_optionsarr: pushedOptions,
      };
      // return
      // 
      // return
      dispatch(addItem(pushedItem));
      
      // getItmData()
    }
    // 
    // return
    setWeightLoading(false);
    setAddLoading(false);
    setShowOpenWeight(false);
    // getItmData()
    getCartDetails();

    return;
    const data_send = {
      product_id: item.id,
      product_count: 1,
      has_option: 0,
      weights: weightstxt,
      has_weights: weights.length > 0 ? 1 : 0,
      // weight:weight.weight,
      // price:weight.price
      // product_options:
    };
    // 
    // return ;
    Axios({
      url: BASE_URL + `cart/add_to_cart`,
      method: "post",
      data: data_send,
    })
      .then((res) => {
        if (res.status == "success") {
          toast.success(res.message);
          getItmData();
          dispatch(fetchCartData());
          getCartDetails();
        } else {
          toast.error(res.message);
        }
      })
      .finally(() => {
        setAddLoading(false);
        setWeightLoading(false);
      });
  };

  const updateData = () => {};
  const addToFav = () => {
    setLoading(true);
    Axios({
      url: BASE_URL + `favorits/change_product_in_fav`,
      method: "post",
      data: {
        user_favorit_id: userId,
        product_favorit_id: item.id,
        // has_option:0
      },
    })
      .then((res) => {
        // 
        if (res.status == "success") {
          toast.success(res.message);
          getItmData();
          // getItmData()
        } else if (res.status == "faild") {
          toast.error(res.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Error");
        }
        // 
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const eqWeights = () => {
    // 
    if (item?.productweights && item?.productweights.length > 0) {
      setProductWeights(
        item?.productweights?.map((item, index) => {
          return { ...item, selected: false };
        })
      );
    }
  };

  const handleGetWeights = (id) => {
    
    setWeightsLoading(true);
    setShowChangeWeight2(true);
    Axios({
      url: BASE_URL + `cart/get_cart_weights/${id}`,
      method: "get",
    })
      .then((res) => {
        
        if (res.status == "success") {
          let myResult = [...res.result];
          setWeights2(
            myResult.map((item) => {
              return { ...item, loading: false };
            })
          );
          setCartId(id);
          
        } else {
          toast.error(language == "ar" ? "سجل الدخول أولا" : "Log in firsly");
        }
      })
      .finally(() => {
        setWeightsLoading(false);
      });
  };

  useEffect(() => {
    setTotalPrice(
      choosedOption && choosedOption?.length
        ? parseFloat(item?.price) +
            choosedOption?.reduce((a, b) => a + parseFloat(b?.totalPrice), 0)
        : parseFloat(item?.price)
    );
  }, [choosedOption]);
  const handleDelFromCart = (id) => {
    setDelLoading(true);
    const data_send = {
      product_id: id,
      product_count: 1,
      has_option: 0,
    };
    Axios({
      url: BASE_URL + "cart/add_to_cart",
      method: "POST",
      data: data_send,
    })
      .then((res) => {
        if (res.status == "success") {
          toast.success(res.message);
          getItmData();
          updateData();

          dispatch(fetchCartData());
          eqData();
          getCartDetails();
        } else {
          toast.error(language == "ar" ? "سجل الدخول أولا" : "Log in firsly");
        }
      })
      .finally(() => {
        setDelLoading(false);
        setAddLoading(false);
      });
  };

  const handleUpdate2 = () => {
    setWeightLoading(true);
    const data_send = {
      cart_id: item.cart_id,
      id: item.id,
      weight: weight.weight,
      price: weight.price,
    };
    Axios({
      url: BASE_URL + "cart/update_weight",
      method: "POST",
      data: data_send,
    })
      .then((res) => {
        if (res.status == "success") {
          getItmData();
          dispatch(fetchCartData());
        }
      })
      .finally(() => {
        setWeightLoading(false);
      });
  };
  const handleDelWeight = (id) => {
    
    setWeights2(
      weights2.map((item) => {
        return { ...item, loading: true };
      })
    );
    Axios({
      url: BASE_URL + `cart/del_weight/${id}`,
      method: "GET",
    })
      .then((res) => {
        
        if (res.status == "success") {
          setWeights2([]);
          handleGetWeights(cartId);
          dispatch(fetchCartData());
          getItmData();
          setShowChangeWeight2(false);
        } else {
          toast.error(language == "ar" ? "سجل الدخول أولا" : "Log in firsly");
        }
      })
      .finally(() => {
        setWeights2(
          weights2.map((item) => {
            return { ...item, loading: true };
          })
        );
      });
  };
  const editWeights = () => {
    // setEditLoading(true);
    let weightsArr = [...weights];
    
    let weightstxt = "";
    for (let i = 0; i < weightsArr.length; i++) {
      if (weightsArr[i].product_count > 0) {
        if (weightstxt == "") {
          weightstxt +=
            weightsArr[i].weight +
            "*green*" +
            (weightsArr[i].product_price || weightsArr[i].price) +
            "*green*" +
            weightsArr[i].product_count +
            "*green*" +
            weightsArr[i].cart_prod_id;
        } else {
          weightstxt +=
            "**green**" +
            weightsArr[i].weight +
            "*green*" +
            (weightsArr[i].product_price || weightsArr[i].price) +
            "*green*" +
            weightsArr[i].product_count +
            "*green*" +
            weightsArr[i].cart_prod_id;
        }
      }
    }
    let localCart = localStorage.getItem('local_els_cart');
    let cartData = localCart && JSON.parse(localCart);
    
    let FilteredCart = cartData.filter((it) => it.id != item.id);
    
    let pushedCart = [...FilteredCart];
    for (let i = 0; i < weightsArr.length; i++) {
      // 
      // return
      let newobj = {
        id: item.id,
        quantity: weightsArr[i].quantity,
        title_ar: item.title_ar,
        title_en: item.title_en,
        image: item.images && item.images[0] && item.images[0].url,
        weight: weightsArr[i].weight,
        discount: item.discount,
        price: weightsArr[i].price,
        price_for: "weight",
        total_price: weightsArr[i].price * 1 * weightsArr[i].quantity * 1,
      };
      // 
      // return
      if (weightsArr[i].quantity * 1 > 0) {
        pushedCart.push(newobj);
      }
    }
    // 
    // return
    dispatch(addWithWeight({ pushedCart: pushedCart }));
    // localStorage.setItem('local_els_cart',JSON.stringify(pushedCart))
    // getData()
    toast.success(language == 'ar' ? 'تم التعديل' : 'Success To Edit');
    // window.location.reload()
    setEditLoading(false);
    setShowChangeWeight(false);
    return;
    const data_send = {
      cart_id: item.cart_id,
      weights: weightstxt,
    };

    // return ;
    Axios({
      url: BASE_URL + `cart/edit_weights`,
      method: "POST",
      data: data_send,
    })
      .then((res) => {
        if (res.status == "success") {
          setWeights([]);
          dispatch(fetchCartData());
          setShowChangeWeight(false);
        } else {
          toast.error(language == "ar" ? "سجل الدخول أولا" : "Log in firsly");
        }
      })
      .finally(() => {
        setEditLoading(false);
      });
  };
  useEffect(() => {
    eqWeights();
  }, []);
  return (
    <div className="ProductDetailsTexts">
      <div className="topText">
        <h5 style={{ color: "#9D071A", fontSize: "25px" }}>
          {item.title && item?.title[language]}
        </h5>
        {item.description_ar || item.description_en ? (
          <p
            dangerouslySetInnerHTML={{
              __html:
                language == "ar" ? item.description_ar : item.description_en,
            }}
            style={{ color: "#3D3D3D", fontSize: "22px" }}
          ></p>
        ) : null}
      </div>
      {/* <span style={{ color: "#3D3D3D", fontSize: "22px" }}>
        {item?.price}
        <span>{language == "ar" ? "دينار كويتي" : "KWD"}</span>
      </span> */}
      <div className="product_weights">
        {offlineCart.items &&
        offlineCart.items.find((it) => it.id == item.id) == undefined &&
        productWeights &&
        productWeights.length &&
        productWeights.length > 0
          ? productWeights &&
            productWeights.length &&
            productWeights.map((itWei, itInd) => {
              return (
                <div
                  onClick={() => {
                    let weightList = [...productWeights];
                    setProductWeights(
                      weightList.map((itwei, itindex) => {
                        if (itwei.id == itWei.id) {
                          return { ...itwei, selected: !itwei.selected };
                        }
                        return { ...itwei };
                      })
                    );
                  }}
                  className={itWei.selected ? "weight act" : "weight"}
                >
                  {itWei.weight} {language == "ar" ? "كيلو ": " KG"}
                </div>
              );
            })
          : null}
      </div>

      {item.returnpolicies && item.returnpolicies?.length ? (
        <span className="return">
          {returnItem}
          <span
            onClick={() => {
              // 
              setShowRetPop(true);
              setReturnpolicies(item.returnpolicies);
            }}
          >
            <>{language == "ar" ? "سياسة الإرجاع" : "Return Policy"}</>
          </span>
        </span>
      ) : null}
      <div className="cart_fav_div">
        {typeof (
          offlineCart.items &&
          offlineCart.items.length > 0 &&
          offlineCart.items.find((it) => it.id == item.id)
        ) != 'object' ? (
          <div className="modalBtnAddToCart btn_cha_wid">
            {addLoading ? (
              <ThreeDots color="red" />
            ) : (
              <button
                style={{
                  backgroundColor: "#EF0A27",
                  border: '1px solid #EF0A27',
                  color: "white",
                  marginLeft: "20px",
                }}
                onClick={() => {
                 
                    if (item?.avilabel_stock > 0) {
                      addToCart();


                    } else {
                      toast.error(
                        language == "ar"
                          ? "لا يمكن شراء المنتج - لقد نفذ من المخزن مؤقتا"
                          : "Product Temporarily Out Of Stock."
                      );
                    }
                  
                }}
              >
                <> {language == "ar" ? "إضافة إلى العربة" : "Add To Cart"}</>
              </button>
            )}
          </div>
        ) : (
          <div className="d-flex justify-content-between w-100">
            {item.productweights && item.productweights.length > 0 ? (
              <>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '9px' }}
                >
                  <button
                    onClick={() => {
                   
                      setShowChangeWeight(true);
                      let pushedWeights = [];
                      let itemWeights = [...item?.productweights];
                      
                      let localCart = localStorage.getItem('local_els_cart');
                      let CartData = localCart && JSON.parse(localCart);
                      for (let i = 0; i < itemWeights.length; i++) {
                        let obj = {
                          ...itemWeights[i],
                        };
                        let findInCart = CartData.find(
                          (it) =>
                            it.id == item.id &&
                            it.weight == itemWeights[i].weight
                        );
                        if (findInCart && Object.keys(findInCart).length > 0) {
                          obj['product_count'] = findInCart.quantity;
                          obj['quantity'] = findInCart.quantity;
                          
                        } else {
                          obj['product_count'] = 0;
                          obj['quantity'] = 0;
                        }
                        pushedWeights.push(obj);
                      }
                      
                      if (true) {
                        setWeights(pushedWeights);
                      } 
                    }}
                    className="btn btn-primary"
                  >
                    <MdOutlineChangeCircle />
                  </button>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                >
                  <p
                    className="in_cart_weig"
                    style={{ backgroundColor: 'white' }}
                  >
                    <FaCartArrowDown
                      onClick={() => {
                        navigate("/cart");
                      }}
                      style={{ color: "#fff", cursor: "pointer" }}
                    />
                  </p>
                  {/* <p
                  onClick={() => {
                    
                    if (true) {
                      handleGetWeights(item.cart_id);
                      
                    } else {
                      // 
                      // handleGetWeightsHere(item.cart_id);
                    }
                  }}
                  className="btn btn-primary"
                >
                  <MdOutlineChangeCircle />
                </p> */}
                </div>
              </>
            ) : delLoading ? (
              <ThreeDots color="red" />
            ) : (
              <ProductInCartMangeButton
                inPrdDet={true}
                item={item}
                checkNotInProd={true}
                delCartItem={handleDelFromCart}
                updateCart={getItmData}
                itemIdToIncrement={item?.id}
                quantity={
                  (offlineCart.items &&
                    offlineCart.items.length > 0 &&
                    offlineCart.items.find((it) => it.id == item.id)?.quantity *
                      1) ||
                  0
                }
                update={update}
              />
            )}
          </div>
        )}
        <>
          {loading ? (
            <Spinner />
          ) : !isItemInFavourite(item?.id) ? (
            <div
              onClick={() => {
                addToFavourite(item);
              }}
              className="not_added_to_fav fav_div"
            >
              <CiHeart />
            </div>
          ) : (
            <div
              onClick={() => {
                removeFromFavourite(item?.id);
              }}
              className="added_to_fav fav_div"
            >
              <FaHeart />
            </div>
          )}
        </>
      </div>
      <PopUp
        open={showRetPop}
        setOpen={setShowRetPop}
        title={
          language == "ar" ? "سياسات إرجاع المنتج" : "Product Return Policies"
        }
      >
        {returnpolicies.map((item, index) => {
          return (
            <div className="retpoli">
              <p>{index + 1} - </p>
              <h5>{item.text}</h5>
            </div>
          );
        })}
      </PopUp>
      <PopUp
        open={showEditWeight}
        setOpen={setShowEditWeight}
        title={language == "ar" ? "تغيير الوزن" : "Change Weight"}
      >
        <div className="weights">
          {weights.map((weightItem, weightIndex) => {
            return (
              <div>
                <p>{weightItem.price}EGP</p>
                <div
                  onClick={() => {
                    setWeight(weightItem);
                    setWeights(
                      weights.map((item) => {
                        return {
                          ...item,
                          checked: item.id == weightItem.id ? true : false,
                        };
                      })
                    );
                  }}
                >
                  <div className="parent">
                    <div
                      onClick={() => {
                        setWeight(weightItem);
                        setWeights(
                          weights.map((item) => {
                            return {
                              ...item,
                              checked: item.id == weightItem.id ? true : false,
                            };
                          })
                        );
                      }}
                      className={
                        weightItem.checked ? "children active" : "children"
                      }
                    ></div>
                  </div>
                  <p>{weightItem.weight} KG</p>
                </div>
              </div>
            );
          })}
        </div>
        {weightLoading ? (
          <Spinner />
        ) : (
          <button
            className="btn btn-success"
            onClick={() => {
              handleUpdate2();
            }}
          >
            تحديث
          </button>
        )}
      </PopUp>
      <PopUp
        open={showOpenWeight}
        setOpen={setShowOpenWeight}
        title={language == "ar" ? "تغيير الوزن" : "Change Weight"}
      >
        <div className="weights">
          {weights.map((weightItem, weightIndex) => {
            return (
              <div className="weights">
                <p>{weightItem.price}EGP</p>
                <input
                  onChange={(e) => {
                    setWeights(
                      weights.map((it) => {
                        return {
                          ...it,
                          product_count:
                            it.id == weightItem.id
                              ? e.target.value
                              : it.product_count,
                          checked:
                            it.id && e.target.value == "" ? false : it.checked,
                        };
                      })
                    );
                  }}
                  className="input_change_count"
                  type="text"
                  placeholder={language == "ar" ? "الكميه" : "Count"}
                />
                <div
                  onClick={() => {
                    setWeight(weightItem);
                    // setWeights(weights.map((item)=>{
                    //   return {...item,checked:item.id==weightItem.id?true:false}
                    // }))
                  }}
                >
                  <div className="parent">
                    <div
                      onClick={() => {
                        setWeight(weightItem);
                        // setWeights(weights.map((item)=>{
                        //   return {...item,checked:item.id==weightItem.id?true:false}
                        // }))
                      }}
                      className={
                        weightItem.product_count > 0
                          ? "children active"
                          : "children"
                      }
                    ></div>
                  </div>
                  <p>{weightItem.weight} KG</p>
                </div>
              </div>
            );
          })}
        </div>
        {weightLoading ? (
          <Spinner />
        ) : (
          <button
            className="btn btn-success"
            onClick={() => {
              addToCart();
            }}
          >
            {language == "ar" ? "طلب" : "make order"}
          </button>
        )}
      </PopUp>

      <PopUp
        open={showChangeWeight}
        title={language == "ar" ? "الأوزان المتاحه" : "Weights"}
        setOpen={setShowChangeWeight}
        children={
          <>
            {" "}
            <div className="weights"></div>
            {false ? (
              <Skeleton count={3} />
            ) : weights && weights?.length ? (
              <div className="weights">
                <h4>{language == "ar" ? "الأوزان" : "Weights"}</h4>
                {weights.map((weightItem, weightIndex) => {
                  return (
                    <div className="price_wei_input_parent">
                      <p>
                        {weightItem.price}
                        {language == "ar" ? "د.ك" : "KWD"}
                      </p>
                      <div className="change_count_div">
                        <span
                          onClick={() => {
                            if (weightItem.quantity == 0) {
                              return;
                            }
                            let newWeights = [...weights];
                            
                            setWeights(
                              newWeights.map((itWei) => {
                                return {
                                  ...itWei,
                                  product_count:
                                    itWei.id == weightItem.id
                                      ? itWei.product_count * 1 - 1
                                      : itWei.product_count,
                                  quantity:
                                    itWei.id == weightItem.id
                                      ? itWei.quantity * 1 - 1
                                      : itWei.quantity,
                                };
                                // return {...itWei,product_count:itWei.id==weightItem.id?itWei:}
                              })
                            );
                            // setWeights(weights.map((itemWei)=>{
                            //   return {...itemWei,product_count:itemWei.id==weightItem.id?weightItem.product_count*1-1:weightItem.product_count}
                            //   // return {...itemWei,product_count:itemWei.id==weightItem.id?itemWei.product_count*1-1:itemWei.product_count*1}
                            // }))
                          }}
                        >
                          -
                        </span>
                        <input
                          disabled={true}
                          value={weightItem.quantity}
                          onChange={(e) => {
                            setWeights(
                              weights.map((it) => {
                                return {
                                  ...it,
                                  product_count:
                                    it.id == weightItem.id
                                      ? e.target.value
                                      : it.product_count,
                                  checked:
                                    it.id == weightItem.id &&
                                    e.target.value == ""
                                      ? false
                                      : it.checked,
                                };
                              })
                            );
                          }}
                          className="count_price_weight_input"
                          type="text"
                          placeholder={language == "ar" ? "الكميه" : "Count"}
                        />
                        <span
                          onClick={() => {
                            let newWeights = [...weights];
                            
                            setWeights(
                              newWeights.map((itWei) => {
                                return {
                                  ...itWei,
                                  product_count:
                                    itWei.id == weightItem.id
                                      ? itWei.product_count * 1 + 1
                                      : itWei.product_count,
                                  quantity:
                                    itWei.id == weightItem.id
                                      ? itWei.quantity * 1 + 1
                                      : itWei.quantity,
                                };
                                // return {...itWei,product_count:itWei.id==weightItem.id?itWei:}
                              })
                            );
                            // setWeights(weights.map((itemWei)=>{
                            //   return {...itemWei,product_count:itemWei.id==weightItem.id?weightItem.product_count*1+1:weightItem.product_count}
                            //   // return {...itemWei,product_count:itemWei.id==weightItem.id?itemWei.product_count*1+1:itemWei.product_count*1}
                            // }))
                          }}
                        >
                          +
                        </span>
                      </div>
                      {weightItem.in_cart == 1 ? (
                        weightItem.loading ? (
                          <Spinner />
                        ) : (
                          <IoMdClose
                            style={{
                              color: "red",
                              fontSize: "32px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleDelWeight(weightItem.cart_prod_id);
                            }}
                          />
                        )
                      ) : null}
                      <div
                      // onClick={() => {
                      //   setWeight(weightItem);
                      //   setTotalPrice(weightItem.price);
                      //   setWeights2(
                      //     weights2.map((item) => {
                      //       return {
                      //         ...item,
                      //         checked:
                      //           item.id == weightItem.id ? true : false,
                      //           product_count:0,
                      //       };
                      //     })
                      //   );
                      // }}
                      >
                        <div className="parent">
                          <div
                            // onClick={() => {
                            //   setWeight(weightItem);
                            //   // 
                            //   setTotalPrice(weightItem.price);
                            //   setWeights2(
                            //     weights2.map((item) => {
                            //       return {
                            //         ...item,
                            //         checked:
                            //           item.id == weightItem.id ? true : false,
                            //       };
                            //     })
                            //   );
                            // }}
                            className={
                              weightItem.product_count > 0
                                ? "children active"
                                : "children"
                            }
                          ></div>
                        </div>
                        <p>{weightItem.weight} KG</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
            <div>
              {editLoading ? (
                <Spinner />
              ) : (
                <button
                  onClick={() => {
                    editWeights();
                  }}
                  className="conf_btn"
                >
                  {language == "ar" ? "تعديل" : "Edit"}
                </button>
              )}
            </div>
          </>
        }
      />

      <PopUp
        open={showChangeWeight2}
        title={language == "ar" ? "الإضافات" : "Options"}
        setOpen={() => {
          setOpen(false);
          setShowChangeWeight2(false);
          // setOpenQuanModel2(false);
          setShowChangeWeight2(false);
        }}
        children={
          <>
            {" "}
            {item?.options && item?.options?.length ? (
              <div className="options_div">
                <h4>{language == "ar" ? "الاضافات" : "Additions"}</h4>
                <Options
                  item={item}
                  handleChangeQuan={handleChangeQuan}
                  options={
                    item?.options && item?.options.length > 0 && item?.options
                  }
                  language={language}
                  setChoosedOptions={setChoosedOptions}
                />
              </div>
            ) : null}
            {weightsLoading ? (
              <ThreeDots color="red" />
            ) : weights2 && weights2?.length ? (
              <div className="weights">
                <h4>{language == "ar" ? "الأوزان" : "Weights"}</h4>
                {weights2.map((weightItem, weightIndex) => {
                  return (
                    <div className="price_wei_input_parent">
                      <p>{weightItem.price}EGP</p>
                      <input
                        value={weightItem.product_count}
                        onChange={(e) => {
                          
                          setWeights2(
                            weights2.map((it) => {
                              return {
                                ...it,
                                product_count:
                                  it.id == weightItem.id
                                    ? e.target.value
                                    : it.product_count,
                                checked:
                                  it.id == weightItem.id && e.target.value == ""
                                    ? false
                                    : it.checked,
                              };
                            })
                          );
                        }}
                        className="count_price_weight_input"
                        type="text"
                        placeholder={language == "ar" ? "الكميه" : "Count"}
                      />
                      {weightItem.in_cart == 1 ? (
                        weightItem.loading ? (
                          <Spinner />
                        ) : (
                          <IoMdClose
                            style={{
                              color: "red",
                              fontSize: "32px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleDelWeight(weightItem.cart_prod_id);
                            }}
                          />
                        )
                      ) : null}
                      <div
                      // onClick={() => {
                      //   setWeight(weightItem);
                      //   setTotalPrice(weightItem.price);
                      //   setWeights2(
                      //     weights2.map((item) => {
                      //       return {
                      //         ...item,
                      //         checked:
                      //           item.id == weightItem.id ? true : false,
                      //           product_count:0,
                      //       };
                      //     })
                      //   );
                      // }}
                      >
                        <div className="parent">
                          <div
                            // onClick={() => {
                            //   setWeight(weightItem);
                            //   // 
                            //   setTotalPrice(weightItem.price);
                            //   setWeights2(
                            //     weights2.map((item) => {
                            //       return {
                            //         ...item,
                            //         checked:
                            //           item.id == weightItem.id ? true : false,
                            //       };
                            //     })
                            //   );
                            // }}
                            className={
                              weightItem.product_count > 0
                                ? "children active"
                                : "children"
                            }
                          ></div>
                        </div>
                        <p>{weightItem.weight} KG</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
            <div className="modalBtnAddToCart">
              {editLoading ? (
                <Spinner />
              ) : (
                <button
                  style={{ backgroundColor: "#ae071c", margin: "auto" }}
                  className="btn btn-success"
                  onClick={() => {
                    editWeights();
                    // handleInCart();
                  }}
                >
                  {language == "ar" ? "تعديل" : "Add To Cart"}
                </button>
              )}
            </div>
          </>
        }
      />
    </div>
  );
};

export default ProductDetailsTexts;
