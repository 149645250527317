import React, { useEffect, useState } from 'react'
import './mappage.css'
import UseGeneral from '../../hooks/useGeneral';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from '../../Axios/base_url';
import { useLocation } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
const MapPage = () => {
  const { language } = UseGeneral();
  const location = useLocation();
  const [data,setData]=useState({
    lat:'',
    lang:'',
    site_location:''
  });
  useEffect(() => {
    document.title =
      language == "ar" ? "الصيداوي | موقعنا   " : "Al-Seedawi | Our Location";
  }, []);
  const [pageLoading,setPageLoading]=useState(true)
  const getSiteInfo=()=>{
    axios.get(BASE_URL+'site_info/get_all_for_user')
    .then((res)=>{
      if(res.data.status=='success'){
        
        setData({...data,site_location:res.data.result?.site_location})
      }
    }).catch(e=>e)
    .finally(()=>{
      setPageLoading(false)
    })
  }
  useEffect(()=>{
    getSiteInfo()
  },[])
  return (
    <div className="">
      <div className='map_page'>
      {
        pageLoading?(
          <iframe
          width={350}
          height={400}
          style={{ margin: "auto" }}
          src="https://lottie.host/embed/0f5cbd90-b98b-4ef9-a1fe-6853af66f359/mzKPtDndKi.json"
        ></iframe>
        ):
        (
          <>
          <div className="map_bread">
          <span>{language=='ar'?'الرئيسيه':'Main'}</span>
          {/* <span>{`>`}</span> */}
          <img src={require('../../assets/images/Arrow.png')} alt="" />
          <span>{language=='ar'?'موقعنا':'Our Location'}</span>
        </div>
        <div className="map_content">
          <div className="right">
            <img src={require('../../assets/locmap.png')} alt="" />
          </div>
          <div className="left">
            <p>{language=='ar'?'مصانع الصيداوى للحلويات':'Al-Seedawi Sweets Factories'}</p>
            <p>{data?.site_location}</p>
            <button>
              {
                language=='ar'?'الإتجاهات':'Directions'
              }
            </button>
          </div>
        </div>
          </>
        )
      }
    </div>
    </div>
  )
}

export default MapPage
