import React, { useEffect, useState } from "react";
import Banner from "../../layouts/home/banner";
import HomeCategories from "../../layouts/home/category";
import HomeProducts from "../../layouts/home/products";
import Ideas from "../../layouts/home/ideas";
import Brands from "../../layouts/home/brands";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import HomeReviews from "../HomeReviews/HomeReviews";
import PopulerProducts from "../../layouts/home/populerProducts";
import HomeRecipes from "../../layouts/home/HomeRecipes/HomeRecipes";
import UseGeneral from "../../hooks/useGeneral";

const Home = () => {
  const { language } = UseGeneral();
  let [lastAdded, setLastAdded] = useState(null);
  let [topNeeded, setTopNeeded] = useState(null);
  let [categories, setCategories] = useState(null);
  let [ramadanBanners, setRamadanBanners] = useState(null);
  let [leftLastAddedBanners, setLeftLastAddedBanners] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [recipes, setRecipes] = useState([]);
  let [rightLastAddedBanners, setRightLastAddedBanners] = useState(null);
  // lastAddedBanners
  // ramadanBanners
  // categories
  const [data, setData] = useState();
  const [sections, setSections] = useState();
  const [brands, setBrands] = useState(null);
  let localData = localStorage.getItem("green_trees");
  let userId = localData && JSON.parse(localData);
  // console.log(userId)
  useEffect(() => {
    document.title =
      language == "ar" ? "الصيداوي | الرئيسية   " : "Al-Seedawi | Home";
  }, []);

    const getData = () => {
      if (
        localStorage.getItem("elsydawyHomePage") &&
        localStorage.getItem("elsydawyHomePage") != null &&
        localStorage.getItem("elsydawyHomePage") != "undefined" &&
        localStorage.getItem("elsydawyHomePage") != undefined
      ) {
        try {
          const data = JSON.parse(localStorage.getItem("elsydawyHomePage"));
          setData((prev) => data);
          setBrands((prev) => data?.brands);
          setCategories((prev) => data?.categories);
          setReviews((prev) => data?.reviews);
          setRecipes((prev) => data?.recipes);
          setRamadanBanners((prev) => data?.Banners);
          setLeftLastAddedBanners((prev) => data?.leftLastAddedBanners);
          setRightLastAddedBanners((prev) => data?.rightLastAddedBanners);
          setSections((prev) => data?.section_categories);
          let ses=[...data?.section_categories]
          let pushedSes=[];
          // console.log(ses,"ses")
          for(let i=0;i<ses.length;i++){
            // let sesProducts=[...ses[i]?.products];
            // for(let k=0;k<sesProducts.length;k++){
            //   for(let j=0;j<offlineCart?.items.length;j++){
            //     if(sesProducts[k].id==offlineCart?.items[j]?.id){
            //       sesProducts[k].in_cart=1;
            //       sesProducts[k].quantity=offlineCart?.items[j].quantity
            //     }
            //   }
            // }
            pushedSes.push(ses[i]);
          }
          console.log(pushedSes)
          setSections(pushedSes)
        } catch (e) {
          console.log(e);
        }
      }
      Axios({
        url: BASE_URL + "homepage/get_all",
        method: "POST",
        data: {
          user_id: userId,
        },
      }).then((res) => {
        
        if (res.status == "success") {
          setLastAdded([]);
          setTopNeeded([]);
          if (res.result)
            localStorage.setItem(
              "elsydawyHomePage",
              JSON.stringify(res.result)
            );
          setData((prev) => res?.result);
          setBrands((prev) => res?.result?.brands);
          setBrands((prev) => res?.result?.reviews);
          setRecipes((prev) => res?.result?.recipes);
          setCategories((prev) => res?.result?.categories);
          setRamadanBanners((prev) => res?.result?.Banners);
          setLeftLastAddedBanners((prev) => res?.result?.leftLastAddedBanners);
          setRightLastAddedBanners((prev) => res?.result?.rightLastAddedBanners);
          setSections((prev) => res?.result?.section_categories);
          let pushedSes=[]
          console.log(res?.result?.section_categories)
          let ses=[...res?.result?.section_categories]
          // console.log(ses,"ses")
          for(let i=0;i<ses.length;i++){
            // console.log(ses[i])
            // console.log(ses[i]?.products)
            // let sesProducts=[...ses[i]?.products];
            // for(let k=0;k<sesProducts.length;k++){
            //   for(let j=0;j<offlineCart?.items.length;j++){
            //     if(sesProducts[k].id==offlineCart?.items[j]?.id){
            //       sesProducts[k].in_cart=1;
            //       sesProducts[k].quantity=offlineCart?.items[j].quantity
            //     }
            //   }
            // }
            pushedSes.push(ses[i])
          }
          setSections(pushedSes)
          console.log(pushedSes)
          // setCategories
        }
      });
    };

  const changequantype = (new_quan, type, id) => {
    if (type == "top_needed") {
      let newTopNeeded = [...topNeeded];
      for (let i = 0; i < newTopNeeded.length; i++) {
        for (let j = 0; j < newTopNeeded[i].options.length; j++) {
          if (newTopNeeded[i].options[j].id == id) {
            newTopNeeded[i].options[j]["quantity"] = new_quan;
          }
        }
      }
      setTopNeeded(newTopNeeded);
    }
  };



  useEffect(() => {
    // if(localStorage.getItem('home_update')){
    //   setCategories(JSON.parse(localStorage.getItem('categories')))
    //   setBrands(JSON.parse(localStorage.getItem('brands')))
    //   setReviews(JSON.parse(localStorage.getItem('reviews')))
    //   setRamadanBanners(JSON.parse(localStorage.getItem('ramadanBanners')))
    //   setRecipes(JSON.parse(localStorage.getItem('recipes')))
    //   setLastAdded(JSON.parse(localStorage.getItem('lastAdded')))
    //   setTopNeeded(JSON.parse(localStorage.getItem('lastTopNeeded')))
    //   setData(JSON.parse(localStorage.getItem('data')))
    //   setLeftLastAddedBanners(JSON.parse(localStorage.getItem('leftLastAddedBanners')))
    //   setRightLastAddedBanners(JSON.parse(localStorage.getItem('rightLastAddedBanners')))
    //   setSections(JSON.parse(localStorage.getItem('sections')))
    // }
    getData();

  }, []);
  return (
    <div>
      <Banner />
      {categories && categories.length > 0 && (
        <HomeCategories categories={categories} />
      )}
      <HomeProducts
        changequantype={changequantype}
        getData={getData}
        lasAdded={lastAdded}
        topNedded={topNeeded}
        banners={ramadanBanners}
        leftlastAddedBanners={leftLastAddedBanners}
        rightLastAddedBanners={rightLastAddedBanners}
        sections={sections}
      />
      {recipes && recipes.length > 0 && <HomeRecipes recipes={recipes} recipe_category={data?.recipe_category}/>}
      {/* <Ideas /> */}
      {/* <Brands brands={brands} /> */}

      {<PopulerProducts sections={sections} />}
      {reviews && reviews.length > 0 && (
        <HomeReviews
          title={
            language == "ar"
              ? "آراء عملائنا عن منتجاتنا"
              : "Our customers opinions about our products"
          }
          reviews={reviews}
        />
      )}
    </div>
  );
};

export default Home;
