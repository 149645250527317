import "bootstrap/dist/css/bootstrap.min.css";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import "rsuite/Loader/styles/index.css";

import "./App.css";
import { AppRoutes } from "./Routes/routes";
import DefaultLayout from "./layouts/defaultLayout";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { fetchCartData } from "./store/cartReducer";
import usePageTracking from "./hooks/pageTracking";
function App() {
  // const s=useSelector(s=>);
  const dispatch = useDispatch();
  const location = useLocation();
  //   const fetchDataHandler = () => {
  //     dispatch(fetchData());
  //   };
    usePageTracking();
    useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [location?.pathname]);
  // const fetchDataHandler = () => {
  //   dispatch(fetchCartData());
  // };
  // useEffect(()=>{
  //   fetchDataHandler()
  // },[])
  return (
    <DefaultLayout>
      <AppRoutes />
      <Toaster containerClassName="toastCon" />
    </DefaultLayout>
  );
}

export default App;
