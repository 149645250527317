import React, { useEffect, useState } from "react";
import "./about.css";
import logo from "../../assets/images/logo.png";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import { ThreeCircles, ThreeDots } from "react-loader-spinner";

import UseGeneral from "../../hooks/useGeneral";
const About = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const { language } = UseGeneral();
  const [logo, setLogo] = useState();
  useEffect(() => {
    document.title =
      language == "ar" ? "الصيداوي | عن الموقع" : "Al-Seedawi | About";
  }, []);
  const getAbouts = () => {
    setLoading(true);
    Axios({
      url: BASE_URL + `site_info/get_all_for_user`,
      method: "GET",
    })
      .then((res) => {
        if (res?.status == "success") {
          setData(res?.result);
          setLogo(res?.result?.logo);
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAbouts();
  }, []);

  return (
    <div className="about-container">
      {loading ? (
        <ThreeCircles color="red" />
      ) : (
        <>
          {data && Object.keys(data).length > 0 ? (
            <>
              <div className="logo">
                <img src={data?.logo} alt="" />
              </div>
              <div className="description">
                {language == "ar" ? data?.description_ar : data?.description_en}
              </div>
            </>
          ) : (
            <div>
              <h5>
                {language == "ar"
                  ? "ما من بيانات الأن"
                  : "There Are Not Data Now"}
              </h5>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default About;
