import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import UseGeneral from "../../hooks/useGeneral";
import PayProducts from "./PayProducts";

const PayCard = ({
  userOrderInfo,
  handleConfirm,
  payType,
  addLoading,
  setAddLoading,
}) => {
  const navigate = useNavigate();
  const { language } = UseGeneral();
  useEffect(() => {
    document.title =
      language == "ar" ? "الصيداوي | تأكيد الطلب   " : "Al-Seedawi | Confirm Order";
  }, []);
  const [pageLoading, setPageLoading] = useState(false);
  const [params] = useSearchParams();
  let localCart = localStorage.getItem('local_els_cart');
  let cartArr = localCart && JSON.parse(localCart);
  let cartDataTotalPrice = 0;
  const [cartTotalPrice, setCartTotalPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [cartData, setCartsData] = useState([]);
  if (cartArr && cartArr.length) {
    for (let k = 0; k < cartArr.length; k++) {
      cartDataTotalPrice += cartArr[k].price * 1 * cartArr[k].quantity;
      if (
        cartArr[k].product_optionsarr &&
        cartArr[k].product_optionsarr.length > 0
      ) {
        let options = [...cartArr[k].product_optionsarr];
        for (let y = 0; y < options.length; y++) {
          cartDataTotalPrice += options[y].price * 1 + options[y].quantity * 1;
        }
      }
    }
  }

  const handlePayment = (data_send) => {
    setPageLoading(true);
    try {
      if (!userOrderInfo?.phone) {
        toast.error(
          language == "ar" ? "أدخل رقم الهاتف أولا" : "Enter Phone Number"
        );
        setAddLoading(false);
        setPageLoading(false);
        return;
      }
      if (
        !localStorage.getItem("order_time") &&
        localStorage.getItem("delivery_time") != "quickly"
      ) {
        toast.error(
          language == "ar" ? "برجاء تحديد وقت التوصيل" : "Choose Time"
        );
        setAddLoading(false);
        setPageLoading(false);
        return;
      }
      if (!localStorage.getItem("activeLocation")) {
        toast.error(
          language == "ar" ? "برجاء اختيار عنوان  " : "Choose Address"
        );
        setAddLoading(false);
        setPageLoading(false);
        return;
      }
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const cartItem = cartArr.map(function (item) {
        return {
          ItemName:
            language !== "ar" && language ? item.title_en : item?.title_ar,
          Quantity: item.quantity,
          UnitPrice: item.price,
        };
      });
      cartItem?.push({
        ItemName: language == "en" ? "Shipping Price" : "رسوم الشحن",
        Quantity: 1,
        UnitPrice: cartDataTotalPrice * 1 > 10 ? 0 : 1.5,
      });

      const raw = JSON.stringify({
        NotificationOption: "ALL",
        CustomerName: userOrderInfo?.name,
        DisplayCurrencyIso: "KWD",
        MobileCountryCode: "+965",
        CustomerMobile: userOrderInfo?.phone,
        CustomerEmail: userOrderInfo?.allData?.email,
        InvoiceValue: parseFloat(
          parseFloat(
            cartDataTotalPrice -
              discount +
              (cartDataTotalPrice * 1 > 10 ? 0 : 1.5)
          ).toFixed(3)
        ),
        CallBackUrl: "https://alseedawionline.com/pay-details",
        ErrorUrl: "https://alseedawionline.com/pay-details",
        Language: language,
        CustomerReference: userOrderInfo?.allData?.id,
        CustomerCivilId: userOrderInfo?.allData?.id,
        UserDefinedField: userOrderInfo?.allData?.name,
        ExpireDate: "",
        CustomerAddress: {
          Block: userOrderInfo?.allAddress
            ? userOrderInfo?.allAddress?.architecture
            : userOrderInfo?.allData?.location?.architecture,
          Street: userOrderInfo?.allAddress
            ? userOrderInfo?.allAddress?.streat
            : userOrderInfo?.allData?.location?.streat,
          HouseBuildingNo: userOrderInfo?.allAddress
            ? userOrderInfo?.allAddress?.architecture
            : userOrderInfo?.allData?.location?.architecture,
          Address: userOrderInfo?.allAddress
            ? userOrderInfo?.allAddress?.region +
              " - " +
              userOrderInfo?.allAddress?.city +
              " - " +
              userOrderInfo?.allAddress?.streat +
              " - " +
              userOrderInfo?.allAddress?.district +
              " - " +
              userOrderInfo?.allAddress?.special_marque
            : userOrderInfo?.allData?.location?.region +
              " - " +
              userOrderInfo?.allData?.location?.city +
              " - " +
              userOrderInfo?.allData?.location?.streat +
              " - " +
              userOrderInfo?.allData?.location?.district +
              " - " +
              userOrderInfo?.allData?.location?.special_marque,

          AddressInstructions: userOrderInfo?.allAddress
            ? userOrderInfo?.allAddress?.region +
              " - " +
              userOrderInfo?.allAddress?.city +
              " - " +
              userOrderInfo?.allAddress?.streat +
              " - " +
              userOrderInfo?.allAddress?.district +
              " - " +
              userOrderInfo?.allAddress?.special_marque
            : userOrderInfo?.allData?.location?.region +
              " - " +
              userOrderInfo?.allData?.location?.city +
              " - " +
              userOrderInfo?.allData?.location?.streat +
              " - " +
              userOrderInfo?.allData?.location?.district +
              " - " +
              userOrderInfo?.allData?.location?.special_marque,
        },
        InvoiceItems: cartItem,
        orderData: JSON.parse(localStorage.getItem("data_send")),
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://alseedawionline.com/elsedawy/api/products/pay?token=" +
          localStorage.getItem("GreenTreesToken"),
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result?.result?.IsSuccess) {
            console.log(result, data_send);
            data_send.new_id = result?.result?.Data?.new_id;
            
            localStorage.removeItem("data_send");
            localStorage.setItem("data_send", JSON.stringify(data_send));
            toast.success(
              language == "ar"
                ? "يتم تحويلك الآن إلى بوابة الدفع"
                : "Now We Switch you to payment getaway"
            );

            window.location.href = result?.result?.Data?.InvoiceURL;
            setPageLoading(false);
          } else {
            toast.error(
              result?.result?.ValidationErrors?.length &&
                result?.result?.ValidationErrors[0]?.Error
            );
            setPageLoading(false);
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setPageLoading(false);
          setAddLoading(false);
        });
    } catch (err) {
      toast.error(language == "ar" ? "حدث خطأ ما" : "Something went wrong");
    }
  };
  const [payId, setPayId] = useState(false);
  useEffect(() => {
    setPayId(params?.get("paymentId"));
  }, [params]);

  useEffect(() => {
    if (payId) {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer rLtt6JWvbUHDDhsZnfpAhpYk4dxYDQkbcPTyGaKp2TYqQgG7FGZ5Th_WD53Oq8Ebz6A53njUoo1w3pjU1D4vs_ZMqFiz_j0urb_BH9Oq9VZoKFoJEDAbRZepGcQanImyYrry7Kt6MnMdgfG5jn4HngWoRdKduNNyP4kzcp3mRv7x00ahkm9LAK7ZRieg7k1PDAnBIOG3EyVSJ5kK4WLMvYr7sCwHbHcu4A5WwelxYK0GMJy37bNAarSJDFQsJ2ZvJjvMDmfWwDVFEVe_5tOomfVNt6bOg9mexbGjMrnHBnKnZR1vQbBtQieDlQepzTZMuQrSuKn-t5XZM7V6fCW7oP-uXGX-sMOajeX65JOf6XVpk29DP6ro8WTAflCDANC193yof8-f5_EYY-3hXhJj7RBXmizDpneEQDSaSz5sFk0sV5qPcARJ9zGG73vuGFyenjPPmtDtXtpx35A-BVcOSBYVIWe9kndG3nclfefjKEuZ3m4jL9Gg1h2JBvmXSMYiZtp9MR5I6pvbvylU_PP5xJFSjVTIz7IQSjcVGO41npnwIxRXNRxFOdIUHn0tjQ-7LwvEcTXyPsHXcMD8WtgBh-wxR8aKX7WPSsT1O8d8reb2aR7K3rkV3K82K_0OgawImEpwSvp9MNKynEAJQS6ZHe_J_l77652xwPNxMRTMASk1ZsJL"
      );

      const raw = JSON.stringify({
        Key: params?.get("paymentId"),
        KeyType: "PaymentId",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://alseedawionline.com/elsedawy/api/products/retrive",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result?.result?.IsSuccess) {
            handleConfirm(
              "card",
              result?.result?.Data?.InvoiceReference,
              result?.result?.Data?.InvoiceTransactions[0]?.TransactionStatus,
              result?.result?.Data?.InvoiceId,
              "card",
              "card",
              "asd"
            );
          }
        })
        .catch((error) => console.error(error));
    }
  }, [payId]);
  const getUserData = () => {
    setPageLoading(true);
    Axios({
      url: BASE_URL + "cart/all_carts_for_user",
      method: "GET",
    })
      .then((res) => {
        if (res.message == "Sission Ended Login Again") {
          // toast.error(res.message);
        }
        if (Array.isArray(res.result.carts)) {
          setDiscount(res.result.money_minus * 1);
          setCartTotalPrice(res.result.total_price * 1);
          // setCartsData(res.result.carts);
        }
      })
      .finally(() => {
        setPageLoading(false);
      })
      .catch((e) => e);
  };

  useEffect(() => {
    getUserData();
    // getMeData()
  }, []);
  return (
    <div className="pay-card-container">
      <PayProducts />

      <div className="d1-flex">
        <p>{language == "ar" ? "اجمالي المنتجات" : "Total Products"}</p>
        <span className="price">
          <strong>{(cartDataTotalPrice * 1)?.toFixed(3)}</strong>{" "}
          {language == "ar" ? "د.ك" : "KWD"}
        </span>
      </div>
      <div className="d1-flex">
        <p>{language == "ar" ? "التوصيل" : "Delivary"}</p>
        <span className="price">
          <strong>
            {" "}
            {(cartDataTotalPrice * 1 > 10 ? 0 : 1.5)?.toFixed(3)}
          </strong>{" "}
          {language == "ar" ? "د.ك" : "KWD"}
        </span>
      </div>
      <div className="d1-flex">
        <p>{language == "ar" ? "الخصم" : "Discount"}</p>
        <span className="price">
          <strong>{discount}</strong> {language == "ar" ? "د.ك" : "KWD"}
        </span>
      </div>
      <div className="dash"></div>
      <div className="d1-flex">
        <strong>{language == "ar" ? "الاجمالي" : "Total"}:</strong>
        <span className="price totalPrice">
          <strong className="totalPriceNum">
            {(
              cartDataTotalPrice -
              discount +
              (cartDataTotalPrice * 1 > 10 ? 0 : 1.5)
            )?.toFixed(3)}
          </strong>{" "}
          {language == "ar" ? "د.ك" : "KWD"}
        </span>
      </div>
      {pageLoading || addLoading ? (
        <ThreeDots color="red" />
      ) : (
        <button
          className="send-request-button"
          onClick={() => {
            payType == 'card'
              ? handleConfirm(
                  payType,
                  payId,
                  null,
                  null,
                  "card",
                  handlePayment,
                  "new process"
                )
              : handleConfirm(payType, payId, "pending");
          }}
        >
          {language == "ar" ? "الدفع" : "Payment"}
        </button>
      )}
    </div>
  );
};

export default PayCard;
