import React, { useEffect, useState } from "react";
import SwiperSlideInstance from "../../../components/swiper/swiperSlide";
import SwiperInstance from "../../../components/swiper";
import { bannerArray } from "../../../data/banners";
import UseGeneral from "../../../hooks/useGeneral";
import "./style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  EffectCube,
  EffectFade,
  FreeMode,
  Navigation,
  Pagination,
} from "swiper/modules";
import { Axios } from "../../../Axios";
import { BASE_URL } from "../../../Axios/base_url";
import { Spinner } from "react-bootstrap";
import { Loader } from "rsuite";
import ContentLoader from "react-content-loader";
const Banner = () => {
  const { language } = UseGeneral();
  const [banners, setBanners] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);

  const getBanners = () => {
    setPageLoading(true);
    Axios({
      url: BASE_URL + `banners/get_all_user`,
      method: "GET",
    })
      .then((res) => {
        // 
        if (res.status == "success") {
          const homeUpdateNumber = localStorage.getItem("home_update");
          if (!homeUpdateNumber) {
            localStorage.setItem('home_update', res?.result?.home_update);
            localStorage.setItem(
              'banners',
              JSON.stringify(res?.result?.banners)
            );
            setBanners(res?.result.banners);
          } else {
            localStorage.setItem(
              'banners',
              JSON.stringify(res?.result?.banners)
            );
            setBanners(
              (prev) => (prev = JSON.parse(localStorage.getItem('banners')))
            );
          }
          

          // setBanners(res.result);
        }
        // 
      })
      .finally(() => {
        setPageLoading(false);
      });
  };
  useEffect(() => {
    if (localStorage.getItem('home_update')) {
      setBanners(JSON.parse(localStorage.getItem('banners')));
      
    }
    getBanners();
  }, []);
  return (
    <div className="rowDiv bannerDiv topBanner">
      {!banners ? (
        <span
          style={{
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <iframe
                    width={350}
                    height={400}
                    style={{ margin: "auto" }}
                    src="https://lottie.host/embed/0f5cbd90-b98b-4ef9-a1fe-6853af66f359/mzKPtDndKi.json"
                  ></iframe>
        </span>
      ) : (
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          // navigation={true}
          effect={"cube"}
          modules={[EffectCube, FreeMode, Autoplay, Pagination]}
          pagination={{ clickable: true }}
          autoplay={{ delay: 5000 }}
          loop={true}
          dir={language != "en" ? "rtl" : "ltr"}
          breakPoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 1,
              spaceBetween: 40,
            },
          }}
        >
          {banners
            ?.filter((item) => item?.banner_type == "box1")
            ?.map((item, index) => {
              
              return (
                <SwiperSlide key={index}>
                  <div
                    className="banner bannerTop"
                    style={{ background: `url(${item?.background_image})` }}
                  >
                    <button
                      className="customBannerButton"
                      onClick={() => {
                        window.location.href = item.link;
                      }}
                      style={{
                        color: `${item?.text_color}`,
                        backgroundColor: `${item?.btn_bg_color}`,
                      }}
                    >
                      {language == "ar"
                        ? item?.button_name
                        : item?.button_name_en}
                    </button>
                    {/* <img
                    src={item?.background_image}
                    alt=""
                    style={{
                      width: "100%",
                      minHeight: "100px",
                      objectFit: "contain",
                      margin: "auto",

                    }}
                  /> */}
                    {/* {} */}
                    {/* <h4>{language === "ar" ? item?.title_ar : item?.title_en}</h4> */}
                    {/* <button className="btn btn-danger">
                    {language === "ar"
                      ? item?.button_name
                      : item?.button_name_en}
                  </button> */}
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      )}
    </div>
  );
};

export default Banner;
