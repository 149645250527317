import React, { useEffect, useState } from "react";
import "./categoryBread.css";
import UseGeneral from "../../hooks/useGeneral";
import Breadcrumb from "../../components/BreadCumbsLinks";
const CategoryBread = ({ newCategory, selectedSubCategory }) => {
  const [category, setCategory] = useState(newCategory);
  
  const { language } = UseGeneral();
  const breadCrumbsLinks = [
    { name: language != "ar" ? "Home" : "الصفحة الرئيسية", path: "/" },
    {
      name: language == "ar" ? category?.title_ar : category?.title_en,
      path: "/Cart",
      not_pass: true,
      active: true,
    },
  ];
  useEffect(() => {
    setCategory(newCategory);
  }, [newCategory]);
  // 
  return (
    <div
      style={{
        backgroundColor: category?.color
          ? category?.color
          : "var(--main-color)",
        color: category?.textColor ? category?.textColor : "white",
      }}
      className="category_bread"
    >
      {/* <div className="category_bread rowDiv"> */}
      <div className="right">
        <Breadcrumb
          selectedSubCategory={selectedSubCategory}
          color={category?.textColor ? category?.textColor : "white"}
          links={breadCrumbsLinks}
          // color={category?.textColor ? category?.textColor : "white"}
        />
        <h5
          style={{
            color: category?.category_color ? category?.category_color :category?.textColor ? category?.textColor : "white",
          }}
        >
          {language == "ar" ? category.title_ar : category.title_en}
        </h5>
        <p
          style={{
            color: category?.textColor ? category?.textColor : "white",
          }}
        >
          {language == "ar"
            ? category?.description_ar
              ? category?.description_ar
              : "لا يوجد وصف له"
            : category?.description_en
            ? category?.description_en
            : "There Are Not Description"}
        </p>
      </div>
      <div className="left">
        <img src={category?.view_image} alt="" />
      {/* </div> */}
      </div>
    </div>
  );
};

export default CategoryBread;
